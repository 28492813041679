import React from "react"
import CookiesPage from "../components/pages/CookiesPolicy"
import { graphql } from "gatsby"

const lang = "en"

const CookiesPolicy = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_en,
    titleSeo: seoDataJson.title_en,
    description: seoDataJson.description_en,
    alternateLanguage: seoDataJson.alternateLanguage_en,
    alternateUrl: seoDataJson.alternateUrl_en,
  }

  return <CookiesPage lang={lang} seoData={seoData} pageContext={pageContext} />
}

export const pageQuery = graphql`
  query cookiesEnQuery {
    seoDataJson(name: { eq: "cookies" }) {
      url_en
      title_en
      description_en
      alternateLanguage_en
      alternateUrl_en
    }
  }
`

export default CookiesPolicy
